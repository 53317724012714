<template>
  <div class="box">
    <div class="my-house">我房间号：{{ floorName }}</div>
    <div class="doc-title">
      <div class="text-style flex3">开锁时间</div>
      <div class="text-style flex1">开门方式</div>
      <div class="text-style flex1">开门状态</div>
    </div>
    <div class="list-record-bg">
      <div class="list-record" v-for="item in obj" v-bind:key="item.id">
        <div class="list-record-text flex3">{{ item.openDate }}</div>
        <div class="list-record-text flex1" v-if="item.openType === 1">
          门禁卡
        </div>
        <div class="list-record-text flex1" v-else-if="item.openType === 2">
          指纹
        </div>
        <div class="list-record-text flex1" v-else-if="item.openType === 3">
          密码
        </div>
        <div class="list-record-text flex1" v-else-if="item.openType === 4">
          远程开锁
        </div>
        <div class="list-record-text flex1" v-else-if="item.openType === 0">
          钥匙
        </div>
        <div class="list-record-text flex1" v-else>未知</div>
        <div class="list-record-text flex1">
          {{ item.status === 0 ? "开锁失败" : "开锁成功" }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getRecord } from "@/api/doorRecord/index";

export default {
  created() {
    this.floorId = this.$route.query.floorId;
    this.floorName = this.$route.query.floorName;
    this.recordList();
  },
  data() {
    return {
      obj: {},
      recordList() {
        let params = {
          floorId: this.floorId,
        };
        getRecord(params).then((ren) => {
          this.obj = ren.data.records;
        });
      },
    };
  },
};
</script>


<style scoped lang="scss">
.box {
  overflow: hidden scroll;
  position: relative;
  height: 100vh;
}
.my-house {
  background: #ffffff;
  border-radius: 1rem;
  margin: 1.33rem;
  font-size: 1.33rem;
  font-weight: 400;
  color: #000000;
  padding-left: 1.33rem;
  line-height: 4.8rem;
}
.doc-title {
  display: flex;
  margin: 0.83rem 1.75rem;
  .text-style {
    height: 1.67rem;
    font-size: 1.17rem;
    font-weight: 400;
    color: #333333;
    line-height: 1.67rem;
  }
}
.list-record-bg {
  height: 100vh;
  background: white;
  background: white;
  border-radius: 1.33rem;

  .list-record {
    display: flex;
    margin-top: 0.5rem;
    padding: 0.83rem 1.75rem;
    .list-record-text {
      height: 1.67rem;
      font-size: 1.17rem;
      font-weight: 400;
      color: #666666;
      line-height: 1.67rem;
    }
  }
}

.flex3 {
  flex: 2;
}
.flex1 {
  flex: 1;
  text-align: right;
}
</style>