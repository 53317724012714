import { get } from '@/axios/axios'

//获取锁授权详情
export const getLockDetails = (params) => {
    return get('/api/lecent-door/lock/android/findAuthPwd', params)
}

//获取门禁授权详情
export const getDoorDetails = (params) => {
    return get('/api/lecent-door/lock/android/doorDevice/authDetail', params)
}