import { get } from '@/axios/axios'

//获取门锁list
export const getLockList = (params) => {
    return get('/api/lecent-door/lock/android/findAuthPwdList', params)
}

//获取门禁list
export const getDoorList = (params) => {
    return get('/api/lecent-door/lock/android/doorDevice/list', params)
}
//取消授权
export const setDelete = (id) => {
    return get('/api/lecent-door/lock/android/cancelAuthPwd?id='+id)
}
