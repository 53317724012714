import Vue from "vue";
import VueRouter from "vue-router";
import doorManage from "@/pages/door-manage.vue";
import doorRecord from "@/pages/door-record.vue";
import doorPassword from "@/pages/door-password.vue";
import doorAbnormal from "@/pages/door-abnormal.vue";
import doorAuthorize from "@/pages/authorize/doorAuthorize.vue";
import authorizeDetails from "@/pages/authorize/authorizeDetails.vue";
import authorizeApply from "@/pages/authorize/authorizeApply.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "doorManage",
    component: doorManage,
  },
  {
    path: "/doorRecord",
    name: "doorRecord",
    component: doorRecord,
  },
  {
    path: "/doorPassword",
    name: "doorPassword",
    component: doorPassword,
  },
  {
    path: "/doorAbnormal",
    name: "doorAbnormal",
    component: doorAbnormal,
  },
  {
    path: "/doorAuthorize",
    name: "doorAuthorize",
    component: doorAuthorize,
  },
  {
    path: "/authorizeDetails",
    name: "authorizeDetails",
    component: authorizeDetails,
  },
  {
    path: "/authorizeApply",
    name: "authorizeApply",
    component: authorizeApply,
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

export default router;
