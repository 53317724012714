<template>
  <div class="box">
    <div class="my-house">我房间号：{{ floorName }}</div>
    <div class="list" v-for="item in obj" v-bind:key="item.id">
      <div class="item-bg">
        <div class="list-title-text">异常消息</div>
        <div class="list-doc">
          <span class="list-doc-text list-color-text1">异常类型:</span>
          <span
            v-if="item.openType === 0"
            class="list-doc-text list-color-text2"
            >钥匙</span
          >
          <span
            v-else-if="item.openType === 1"
            class="list-doc-text list-color-text2"
            >门禁卡</span
          >
          <span
            v-else-if="item.openType === 2"
            class="list-doc-text list-color-text2"
            >指纹</span
          >
          <span
            v-else-if="item.openType === 3"
            class="list-doc-text list-color-text2"
            >密码</span
          >
          <span
            v-else-if="item.openType === 4"
            class="list-doc-text list-color-text2"
            >远程开锁</span
          >
          <span v-else class="list-doc-text list-color-text2">未知</span>
        </div>
        <div class="list-doc">
          <span class="list-doc-text list-color-text1">异常次数:</span>
          <span class="list-doc-text list-color-text2"
            >{{ item.errNumber }}次</span
          >
        </div>
        <div class="list-doc">
          <span class="list-doc-text list-color-text1">异常内容:</span>
          <span class="list-doc-text list-color-text2">{{ item.errMsg }}</span>
        </div>
        <div class="list-doc">
          <span class="list-doc-text list-color-text1">异常时间:</span>
          <span class="list-doc-text list-color-text2">{{
            item.createTime
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAbnormal } from "@/api/doorAbnormal/index";
export default {
  created() {
    this.floorId = this.$route.query.floorId;
    this.floorName = this.$route.query.floorName;
    this.abnormalList();
  },
  data() {
    return {
      obj: {},
      abnormalList() {
        let params = {
          floorId: this.floorId,
        };
        getAbnormal(params).then((res) => {
          this.obj = res.data.records;
        });
      },
    };
  },
};
</script>


<style scoped lang="scss">
.box {
  overflow: hidden scroll;
  position: relative;
  height: 100vh;
}
.my-house {
  background: #ffffff;
  border-radius: 1rem;
  margin: 1.33rem;
  font-size: 1.33rem;
  font-weight: 400;
  color: #000000;
  padding-left: 1.33rem;
  line-height: 4.8rem;
}
.list {
  padding: 0.7rem 1.3rem;
  .item-bg {
    margin: auto;
    height: 13.67rem;
    background: #ffffff;
    border-radius: 1rem;
    padding: 1rem 1.3rem;
    box-sizing: border-box;
    .list-doc {
      margin-top: 0.8rem;
    }
    .list-title-text {
      font-size: 1.17rem;
      font-weight: 600;
      color: #000000;
    }

    .list-doc-text {
      font-size: 1.17rem;
      font-weight: 400;
    }

    .list-color-text1 {
      color: #999999;
    }
    .list-color-text2 {
      color: #333333;
    }
  }
}
</style>