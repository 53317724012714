/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken 是否需要token
 */
import axios from "axios";
import { getAccessToken, clearAllStorage } from "@/utils/store";

export const serialize = (data) => {
  let list = [];
  Object.keys(data).forEach((ele) => {
    list.push(`${ele}=${data[ele]}`);
  });
  return list.join("&");
};
//设置默认请求地址
// axios.defaults.baseURL = "https://gateway.community.leliven.com";
// axios.defaults.baseURL = "http://test.gateway.community.leuplus.com";
axios.defaults.baseURL = "/";
//默认超时时间
axios.defaults.timeout = 600000;
//返回其他状态码
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500;
};
//跨域请求，允许保存cookie
axios.defaults.withCredentials = false;

//http request拦截
axios.interceptors.request.use(
  (config) => {
    // 发起请求前 配置请求头
    const meta = config.meta || {};
    config.headers["Authorization"] = `Basic c2FiZXI6c2FiZXJfc2VjcmV0`;
    if (getAccessToken()) {
      config.headers["Blade-Auth"] = "bearer " + getAccessToken();
      // 让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改
    }
    //headers中配置serialize为true开启序列化
    if (config.method === "post" && meta.isSerialize === true) {
      config.data = serialize(config.data);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//http response 拦截
axios.interceptors.response.use(
  (res) => {
    //获取状态码
    const status = res.data.code || res.status;
    const message = res.data.msg || res.data.message || "未知错误";
    //如果是401则跳转到登录页面
    if (status === 401) {
      // 无登录页面 静默处理
      window.sessionStorage.clear();
      clearAllStorage();
      // router.push({path:"/"})
      // Toast(message+",请退出重试!")
      // window.location.reload()
      //401处理
      return true;
    }
    if (status === 409) {
      // Toast(message)
      return true;
    }
    if (status === 400) {
      // Toast(message)
      return res.data;
    }
    // 如果请求为非200否者默认统一处理
    if (status !== 200) {
      // Toast(message)
      return Promise.reject(new Error(message));
    }
    // 根据实际情况做数据拦截返回
    return res.data;
  },
  (error) => {
    return Promise.reject(new Error(error));
  }
);

const get = (url, data) =>
  axios.get(url, {
    params: data,
  });

const post = (url, data) => axios.post(url, data);

export { get, post, axios as request };
