// token
export const getAccessToken = () => {
  return sessionStorage.getItem("accessToken");
};

export const setAccessToken = (accessToken) => {
  return sessionStorage.setItem("accessToken", accessToken);
};

export const clearAccessToken = () => {
  return sessionStorage.removeItem("accessToken");
};

export const clearAllStorage = () => {
  sessionStorage.clear();
  sessionStorage.clear();
};
