<template>
  <div class="box">
    <div class="search" v-if="false">
      <form action="/">
        <van-search v-model="value" show-action placeholder="请输入搜索关键词" shape="round">
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
      </form>
    </div>
    <div class="list-box">
      <div class="tis">{{ flag == 1 ? "门禁授权列表" : "门锁授权列表" }}</div>

      <div class="item-bg" v-for="item in objList" :key="item.id" @click="topages(1, item.id)">
        <div class="status">
          <img class="status-img" :src="src(item.status)" alt="" />
        </div>
        <div class="name">{{ item.name }}</div>
        <div style="display: flex;justify-content: space-between;align-items: center;">

          <div>
            <div class="doc">
              <div class="title">
                {{ flag == 1 ? "授权小区门禁：" : "授权房间号：" }}
              </div>
              <div class="con">
                {{ flag == 1 ? item.buildName + item.unitName : item.floorName }}
              </div>
            </div>
            <div class="doc">
              <div class="title">密码：</div>
              <div class="con">{{ flag == 1 ? item.openCode : item.pwd }}</div>
            </div>
          </div>
          <!-- <div>
            
          </div> -->

          <van-button type="warning" size="mini" @click.stop="expurgate(item)" v-if="item.status==1">取消授权</van-button>
        </div>
        <div class="doc">
          <div class="title">期限：</div>
          <div class="con">
            {{
              flag == 1
              ? item.createTime + "-" + item.expireTime
              : item.startDate + "-" + item.endDate
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="add" @click="topages(2, '')">
      <div class="btn-str">
        {{ flag == 1 ? "新增门禁钥匙" : "新增门锁钥匙" }}
      </div>
      <van-icon name="add-o" color="#ffffff" size="1.8rem" />
    </div>
  </div>
</template>

<script>
import { getLockList, getDoorList, setDelete } from "@/api/authorize/doorAuthorize";
export default {
  created() {
    this.flag = this.$route.query.mark;
    if (this.flag == 1) {
      //门禁授权
      this.doorGetList();
    } else {
      //门锁授权
      this.lockGetList();
    }
  },
  data() {
    return {
      //1门禁授权 2门锁授权
      flag: -1,
      value: "",
      objList: [],
    };
  },
  methods: {
    lockGetList() {
      getLockList({ param: this.value, current: 1, size: 999 }).then((res) => {
        this.objList = res.data.records;
      });
    },
    expurgate(data) {
      console.log(data);

      this.$dialog.confirm({
        title: '提示',
        message: '是否删除该授权',
        confirmButtonColor: '#3186ff',
      })
        .then(() => {
          setDelete(data.id).then(() => {
            this.$toast("取消授权成功");
            if (this.flag == 1) {
              //门禁授权
              this.doorGetList();
            } else {
              //门锁授权
              this.lockGetList();
            }
          })
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    doorGetList() {
      getDoorList({ param: this.value }).then((res) => {
        this.objList = res.data;
      });
    },
    src(val) {
      let src;
      if (val == 1) {
        //有效
        src = require("/public/img/authorize/status-icon1.png");
      } else {
        //无效
        src = require("/public/img/authorize/status-icon2.png");
      }
      return src;
    },

    topages(val, ids) {
      if (val == 1) {
        this.$router.push({
          path: "/authorizeDetails",
          query: {
            flag: this.flag,
            id: ids,
          },
        });
      } else {
        this.$router.push({
          path: "/authorizeApply",
          query: {
            flag: this.flag,
          },
        });
      }
    },
    onSearch() {
      if (this.flag == 1) {
        //门禁授权
        this.doorGetList();
      } else {
        //门锁授权
        this.lockGetList();
      }
    },
  },
};
</script>


<style scoped lang="scss">
.box {
  .add {
    padding: 1.3rem;
    background: #3186ff;
    box-shadow: -0.2rem -0.2rem 0.3rem 0rem #ffffff,
      0.3rem 0.2rem 0.5rem 0rem #a9c9e5;
    border-radius: 2.4rem;
    position: fixed;
    margin: 0 1.6rem 3.6rem 1.6rem;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn-str {
      font-size: 1.6rem;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
      padding-right: 0.8rem;
    }
  }

  .search {
    margin-top: 2rem;
  }

  .list-box {
    padding: 1.8rem;

    .tis {
      font-size: 1.4rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #999999;
      line-height: 2rem;
    }

    .item-bg {
      margin-top: 1.2rem;
      background: #ffffff;
      border-radius: 1.2rem;
      padding: 1.2rem 1.6rem;
      position: relative;

      .status {
        position: absolute;
        top: 0;
        right: 0;

        .status-img {
          width: 7.2rem;
          height: 2.4rem;
        }
      }

      .name {
        font-size: 1.4rem;
        font-weight: 600;
        margin-top: 1.5rem;
        color: #000000;
        overflow: hidden; //不能超出本容器。包括子容器。超出不显示
        text-overflow: ellipsis; //显示省略号
        white-space: nowrap; //不换行。也就是显示一行
      }

      .doc {
        display: flex;
        align-items: center;

        .title {
          font-size: 1.4rem;
          font-weight: 400;
          color: #999999;
          padding-top: 0.5rem;
        }

        .con {
          flex: 1;
          font-size: 1.4rem;
          font-weight: 400;
          color: #333333;
          overflow: hidden; //不能超出本容器。包括子容器。超出不显示
          text-overflow: ellipsis; //显示省略号
          box-sizing: border-box;
          white-space: nowrap; //不换行。也就是显示一行
        }
      }
    }
  }
}
</style>