import { get, post } from '@/axios/axios'

//获取授权房屋列表
export const getFloorList = (params) => {
    return get('/api/lecent-door/lock/android/floor/list', params)
}
//授权房屋
export const postAddPwds = (params) => {
    return post('/api/lecent-door/lock/android/addAuthPwd', params)
}

//获取授权楼栋单元列表
export const getSelect = () => {
    return get('/api/lecent-door/lock/android/doorDevice/select')
}
//授权楼栋单元
export const postPwdDoor = (params) => {
    return post('/api/lecent-door/lock/android/doorDevice/createOpenCode', params)
}