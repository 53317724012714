import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { setAccessToken, getAccessToken } from "@/utils/store";
import Vant from "vant";
import "vant/lib/index.css";
import VueClipboard from "vue-clipboard2";
import VConsole from "vconsole";

Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  let access_token = to.query.token;
  if (!getAccessToken()) {
    setAccessToken(access_token);
    next();
  } else {
    next();
  }
});
// const vc = new VConsole();
Vue.use(Vant);
Vue.use(VueClipboard);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
