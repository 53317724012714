<template>
  <div class="box">
    <div class="dialog" @click="dialogHidde" v-show="isAdd">
      <div class="dialog-bg">
        <div class="doc">{{ dialogStr }}</div>
      </div>
    </div>
    <div class="tips">
      <div class="my-house">
        <!-- <div class="my-house-name">我房间号：XXX-XX-房</div> -->
        <select class="my-house-name" v-model="columeId">
          <option selected hidden disabled value="">
            {{
              columeTypeArr[0]
                ? " 我房间号：" +
                  columeTypeArr[0].unitName +
                  columeTypeArr[0].floorName
                : "暂无房屋"
            }}
          </option>
          <option
            v-for="item in columeTypeArr"
            :key="item.id"
            :value="item.floorId"
          >
            我房间号： {{ item.unitName + item.floorName }}
          </option>
        </select>
        <div class="my-house-kmjl" @click="toURL('kmjl')">
          开门记录
          <div class="xhx"></div>
        </div>
      </div>
    </div>

    <div class="menu-manage-box">
      <div class="menu-left">
        <div class="menu-top" @click="toURL('ckmm')">
          <img
            src="../../public/img/look-password-icon.png"
            style="height: 5.5rem; width: 5.5rem"
          />
        </div>
        <div class="menu-text" @click="toURL('ckmm')">查看密码</div>
        <div class="menu-botm" @click="toURL('mjsq')">
          <img
            src="../../public/img/warrant-door-icon.png"
            style="height: 5.5rem; width: 5.5rem"
          />
        </div>
        <div class="menu-text" @click="toURL('mjsq')">门禁授权</div>
      </div>
      <div class="menu-centent" @click="openLockClick">
        <div class="menu-open">
          <div class="open-lock">点击开锁</div>
          <div class="open-lock-tis">(远程开锁)</div>
        </div>
      </div>
      <div class="menu-rigth">
        <div class="menu-top" @click="toURL('ycxx')">
          <img
            src="../../public/img/warrant-err-icon.png"
            style="height: 5.5rem; width: 5.5rem"
          />
        </div>
        <div class="menu-text" @click="toURL('ycxx')">异常信息</div>
        <div class="menu-botm" @click="toURL('mssq')">
          <img
            src="../../public/img/warrant-lock-icon.png"
            style="height: 5.5rem; width: 5.5rem"
          />
        </div>
        <div class="menu-text" @click="toURL('mssq')">门锁授权</div>
      </div>
    </div>

    <!-- 老界面 -->
    <div class="open-lock-div" v-if="false">
      <div class="open-lock-cl" @click="openLockClick">
        <span class="djks">点击开锁</span>
        <span class="ycks">(远程开锁)</span>
      </div>
    </div>
    <div class="bottom-nav" @click="selectData" v-if="false">
      <div class="menu">
        <div class="menu-felx" @click="toURL('kmjl')">
          <img class="menu-img" src="../../public/img/kmjl.png" alt="" />
          <div class="menu-text">开门记录</div>
        </div>
        <div class="menu-felx" @click="toURL('ckmm')">
          <img class="menu-img" src="../../public/img/ckmm.png" alt="" />
          <div class="menu-text">查看密码</div>
        </div>
        <div class="menu-felx" @click="toURL('ycxx')">
          <img class="menu-img" src="../../public/img/ycxx.png" alt="" />
          <div class="menu-text">异常信息</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFloorList, openLock } from "@/api/doorManage/index";
export default {
  created() {
    getFloorList({ current: 1, size: 999 }).then((ren) => {
      this.obj = ren;
      this.columeTypeArr = ren.data.records;
      this.columeId = this.columeTypeArr[0].floorId;
    });
  },

  data() {
    return {
      obj: {},
      columeObj: {},
      columeId: "", //选择id
      columeTypeArr: [], //下拉数据
      dialogStr: "", //开锁提示语
      isAdd: false,
    };
  },
  methods: {
    toURL(flag) {
      this.selectData();
      if (!this.columeObj || !this.columeObj?.floorId) {
        this.dialogStr = "请选择房屋";
        this.isAdd = true;
        return;
      }
      if (flag === "kmjl") {
        this.$router.push({
          path: "/doorRecord",
          query: {
            floorId: this.columeObj.floorId,
            floorName: this.columeObj.unitName + this.columeObj.floorName,
          },
        });
      } else if (flag === "ckmm") {
        this.$router.push({
          path: "/doorPassword",
          query: {
            floorId: this.columeObj.floorId,
            floorName: this.columeObj.unitName + this.columeObj.floorName,
          },
        });
      } else if (flag === "ycxx") {
        this.$router.push({
          path: "/doorAbnormal",
          query: {
            floorId: this.columeObj.floorId,
            floorName: this.columeObj.unitName + this.columeObj.floorName,
          },
        });
      } else if (flag === "mjsq") {
        this.$router.push({
          path: "/doorAuthorize",
          query: {
            mark: 1,
          },
        });
      } else if (flag === "mssq") {
        this.$router.push({
          path: "/doorAuthorize",
          query: {
            mark: 2,
          },
        });
      }
    },

    dialogShow(success) {
      if (success) {
        this.dialogStr = "开锁成功";
      } else {
        this.dialogStr = "开锁失败";
      }
      this.isAdd = true;
    },

    dialogHidde() {
      this.isAdd = false;
    },
    selectData() {
      this.columeObj = this.columeTypeArr.filter(
        (item) => item.floorId === this.columeId
      )[0];
    },
    openLockClick() {
      this.selectData();
      if (!this.columeObj || !this.columeObj?.floorId) {
        this.dialogStr = "请选择房屋";
        this.isAdd = true;
        return;
      }
      let params = {
        floorId: this.columeObj.floorId,
      };
      openLock(params).then((ren) => {
        if (ren.code === 200) {
          this.dialogShow(ren.success);
        } else {
          this.dialogShow(false);
        }
      });
    },
  },
};
</script>


<style scoped lang="scss">
.box {
  overflow: hidden;
  position: relative;
  height: 100vh;
}

.tips {
  background-image: url("../../public/img/my-house-bg1.png");
  background-size: 100%;
  height: 32rem;
  text-align: center;
  .my-house {
    overflow: hidden;
    height: 4.67rem;
    background: #ffffff;
    border-radius: 1rem;
    margin: auto;
    margin: 1.33rem;
    display: flex;
    justify-content: space-between;
    .my-house-name {
      text-align: center;
      display: block;
      height: 4.67rem;
      width: 100%;
      font-size: 1.33rem;
      font-weight: 400;
      color: #000000;
      line-height: 4.67rem;
      /*Chrome和Firefox里面的边框是不一样的，所以复写了一下*/
      border: solid 0px #000;
      padding-right: 1.6rem;
    }
    .my-house-kmjl {
      font-size: 1.4rem;
      font-weight: 500;
      color: #999999;
      overflow: hidden;
      white-space: nowrap;
      padding-right: 3rem;
      padding-left: 0.5rem;
      margin: auto;
      .xhx {
        width: 5.5rem;
        height: 0.1rem;
        background: #eeeeee;
        text-align: center;
        margin-top: 0.2rem;
      }
    }
  }
}

.menu-manage-box {
  width: 100vw;
  padding: 1.4rem 0;
  position: absolute;
  bottom: 0%;
  display: flex;
  height: 20rem;
  justify-content: space-evenly;
  .menu-top {
    text-align: center;
    padding-top: 0.6rem;
  }
  .menu-botm {
    text-align: center;
    padding-top: 1.8rem;
  }
  .menu-text {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 400;
    color: #666666;
    line-height: 2rem;
  }
  .menu-left {
    background: url("../../public/img/menu-left-img.png") no-repeat;
    background-size: 100% 100%;
    height: 20rem;
    width: 10.9rem;
  }
  .menu-centent {
    background: url("../../public/img/menu-content-img.png") no-repeat;
    background-size: 100% 100%;
    height: 20rem;
    width: 10.9rem;
    text-align: center;
    .menu-open {
      padding-top: 13.2rem;
      .open-lock {
        font-size: 1.6rem;
        font-weight: 600;
        color: #3186ff;
        line-height: 2.2rem;
      }
      .open-lock-tis {
        font-size: 1.2rem;
        font-weight: 400;
        color: #999999;
        line-height: 1.7rem;
        padding-top: 0.4rem;
      }
    }
  }
  .menu-rigth {
    background: url("../../public/img/menu-rigth-img.png") no-repeat;
    background-size: 100% 100%;
    height: 20rem;
    width: 10.9rem;
  }
}

.open-lock-div {
  margin: auto;
  background-image: url("../../public/img/open-door-btn-bg.png");
  background-size: 100% 100%;
  width: 17rem;
  height: 6rem;
  line-height: 5rem;
  overflow: hidden;
  margin-top: 1rem;
  .open-lock-cl {
    overflow: hidden;
    height: 3.5rem;
    width: 13rem;
    margin: auto;
    margin-top: 1.2rem;
    background: #3186ff;
    text-align: center;
    line-height: 3.5rem;
    border-radius: 1.2rem;
    .djks {
      height: 1.83rem;
      font-size: 1.33rem;
      font-weight: 600;
      color: #ffffff;
      line-height: 1.83rem;
    }
    .ycks {
      height: 1.42rem;
      font-size: 1rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 1.42rem;
      margin-left: 0.5rem;
    }
  }
}

.bottom-nav {
  text-align: center;
  position: absolute;
  bottom: 0;
  background: url("../../public/img/bottom-nav.png");
  background-size: 100% 100%;
  width: 100vw;
  height: 16rem;
  .menu {
    padding: 3.5rem;
    display: flex;
    .menu-felx {
      flex: 1;
    }
    .menu-img {
      width: 5.67rem;
      height: 5.67rem;
    }
    .menu-text {
      height: 1.67rem;
      font-size: 1.17rem;
      font-weight: 400;
      color: #666666;
      line-height: 1.67rem;
    }
  }
}

.dialog {
  position: absolute;
  margin: auto;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
  .dialog-bg {
    margin: auto;
    transform: translate(0, 100%);
    width: 25rem;
    height: 20rem;
    background: white;
    border-radius: 2rem;
    .doc {
      font-size: 2rem;
      text-align: center;
      line-height: 20rem;
    }
  }
}
</style>