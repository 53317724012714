<template>
  <div class="box">
    <div class="details-box">
      <div>
        <div class="title">授权小区</div>
        <div class="content">
          {{
            flag == 1
              ? sqname
              : obj.communityName +
                "/" +
                obj.groupName +
                "/" +
                obj.buildingName +
                "/" +
                obj.unitName +
                "/" +
                obj.floorName
          }}
        </div>
      </div>
      <div>
        <div class="title">授权名称</div>
        <div class="content">{{ flag == 1 ? obj.authUserName : obj.name }}</div>
      </div>
      <div>
        <div class="title">开门密码</div>
        <div class="content">{{ flag == 1 ? obj.openCode : obj.pwd }}</div>
      </div>
      <div>
        <div class="title">生效时间</div>
        <div class="content">
          {{ flag == 1 ? obj.createTime : obj.startDate }}
        </div>
      </div>
      <div>
        <div class="title">失效时间</div>
        <div class="content">
          {{ flag == 1 ? obj.expireTime : obj.endDate }}
        </div>
      </div>
      <div>
        <div class="title">时效</div>
        <div class="content">{{ obj.status == 0 ? "已过期" : "有效中" }}</div>
      </div>
      <div>
        <!-- 复制文本 https://www.jianshu.com/p/2475abf11c18 -->
        <div
          class="copy"
          v-clipboard:copy="value"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          复制文本
        </div>
        <div class="tis">温馨提示：复制文本后可通过粘贴方式发送给</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLockDetails,
  getDoorDetails,
} from "@/api/authorize/authorizeDetails";
export default {
  data() {
    return {
      value: "",
      //1门禁授权 2门锁授权
      flag: -1,
      id: "",
      obj: {},
      sqname: "",
    };
  },
  created() {
    this.flag = this.$route.query.flag;
    this.id = this.$route.query.id;
    if (this.flag == 1) {
      this.getDoorDetailsData();
    } else {
      this.getLockDetailsData();
    }
  },
  methods: {
    getLockDetailsData() {
      getLockDetails({ id: this.id }).then((res) => {
        this.obj = res.data;
        this.copyStr();
      });
    },
    getDoorDetailsData() {
      getDoorDetails({ id: this.id }).then((res) => {
        this.obj = res.data;
        this.sqname =
          (this.obj.communityName ? this.obj.communityName + "/" : "") +
          (this.obj.groupName ? this.obj.groupName + "/" : "") +
          (this.obj.buildingName ? this.obj.buildingName + "/" : "") +
          (this.obj.unitName ? this.obj.unitName : "");
        this.copyStr();
      });
    },
    copyStr() {
      let sqxq =
        this.flag == 1
          ? this.obj.communityName +
            "/" +
            this.obj.groupName +
            "/" +
            this.obj.buildingName +
            "/" +
            this.obj.unitName
          : this.obj.communityName +
            "/" +
            this.obj.groupName +
            "/" +
            this.obj.buildingName +
            "/" +
            this.obj.unitName +
            "/" +
            this.obj.floorName;
      console.log(sqxq);
      this.value = `
      授权小区
      ${sqxq}
      授权名称
      ${this.flag == 1 ? this.obj.authUserName : this.obj.name}
      开门密码
      ${this.flag == 1 ? this.obj.openCode : this.obj.pwd}
      生效时间
      ${this.flag == 1 ? this.obj.createTime : this.obj.startDate}
      失效时间
      ${this.flag == 1 ? this.obj.expireTime : this.obj.endDate}
      时效
      ${this.obj.status == 0 ? "已过期" : "有效中"}
      `;
    },
    // 复制成功时的回调函数，要有值才能复制成功
    onCopy(e) {
      console.log("内容已复制到剪切板！");
      this.$toast("内容已复制到剪切板！");
    },
    // 复制失败时的回调函数
    onError(e) {
      console.log("抱歉，复制失败！");
      this.$toast("抱歉，复制失败！");
    },
  },
};
</script>


<style scoped lang="scss">
.box {
  padding: 2.1rem 1.6rem;
  .details-box {
    background: #ffffff;
    border-radius: 1.2rem;
    padding: 0 1.2rem 1.6rem 1.2rem;
    .copy {
      font-size: 1.4rem;
      font-weight: 400;
      color: #3186ff;
      line-height: 2rem;
      padding-top: 1.6rem;
      display: flex;
    }
    .copy::before {
      content: "";
      display: block;
      background: url("../../../public/img/authorize/copy-icon.png") no-repeat;
      background-size: 100% 100%;
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 0.8rem;
    }
    .tis {
      font-size: 1.2rem;
      font-weight: 400;
      color: #666666;
      line-height: 1.7rem;
      padding-left: 2.2rem;
      padding-top: 0.4rem;
    }
    .title {
      font-size: 1.4rem;
      font-weight: 400;
      color: #999999;
      line-height: 2rem;
      padding-top: 1.6rem;
    }
    .content {
      font-size: 1.4rem;
      font-weight: 600;
      color: #333333;
      line-height: 2rem;
      padding-top: 0.4rem;
    }
  }
}
</style>