<template>
  <div class="box">
    <div class="my-house">我房间号：{{ floorName }}</div>
    <div class="see-password">
      <div class="doc-text">我的门锁开门密码</div>
      <div class="password-text" v-if="!isUpdata">{{ obj }}</div>
      <input class="password-text password-inp" type="number" maxlength="8" @input="limitPwd" placeholder="请输入密码" v-model="pwd" v-if="isUpdata" />
    </div>

    <div class="updata" @click="alter()">{{ updataStr }}</div>
  </div>
</template>

<script>
import { getPwd, updatePwd } from "@/api/lockPassword/index";
export default {
  created() {
    this.floorId = this.$route.query.floorId;
    this.floorName = this.$route.query.floorName;
    this.openPassword();
  },
  methods: {
    limitPwd() {
      if (this.pwd.length > 8) {
        this.pwd = this.pwd.slice(0, 8);
      }
    },
    alter() {
      if (this.updataStr == "修改密码") {
        this.updataStr = "确认修改";
        this.isUpdata = true;
      } else {
        if (!this.pwd || this.pwd.length == 8) {
          this.$toast("请输入8位数密码");
        }
        //修改逻辑
        updatePwd({ floorId: this.floorId, pwd: this.pwd }).then((res) => {
          this.$toast("修改密码成功");
          this.updataStr = "修改密码";
          this.isUpdata = false;
          this.pwd = "";
          this.openPassword();
        });
      }
    },
    openPassword() {
      let params = {
        floorId: this.floorId,
      };
      getPwd(params).then((res) => {
        console.log(typeof (res.data));
        if (JSON.stringify(res.data) != "{}") {
          this.obj = res.data;
        }
      });
    },
  },
  data() {
    return {
      floorId: "",
      floorName: "",
      obj: "",
      updataStr: "修改密码",
      isUpdata: false,
      pwd: "",
    };
  },
  watch: {
    pwd() {
      if (this.pwd) {
      }
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  overflow: hidden;
  position: relative;
  height: 100vh;
  .updata {
    margin: 3.7rem 1.6rem;
    padding: 1.3rem;
    text-align: center;
    background: #3186ff;
    box-shadow: -0.2rem -0.2rem 0.3rem 0rem #ffffff,
      0.3rem 0.2rem 0.5rem 0rem #a9c9e5;
    border-radius: 2.4rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: #ffffff;
    line-height: 2.2rem;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
  }
}

.my-house {
  background: #ffffff;
  border-radius: 1rem;
  margin: 1.33rem;
  font-size: 1.33rem;
  font-weight: 400;
  color: #000000;
  padding-left: 1.33rem;
  line-height: 4.8rem;
}
.see-password {
  overflow: hidden;
  background: url("../../public/img/door-password.png");
  background-size: 100% 100%;
  width: 100vw;
  height: 11rem;
  margin-top: 14rem;
  text-align: center;
  .doc-text {
    font-size: 1.17rem;
    font-weight: 400;
    color: #666666;
    line-height: 1.67rem;
    margin-top: 3rem;
  }
  .password-text {
    height: 3.75rem;
    font-size: 2.67rem;
    font-weight: 600;
    margin-top: 0.3rem;
    color: #000000;
    letter-spacing: 1.5rem;
  }
  .password-inp {
    border: 0px;
    background: rgba(0, 0, 0, 0);
    text-align: center;
  }
  .password-inp::placeholder {
    font-size: 1.4rem;
    font-weight: 400;
    color: #999999;
    line-height: 2rem;
  }
}
</style>