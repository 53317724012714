<template>
  <div class="box">
    <div class="from-box">
      <div class="v-form-item-title">
        <span class="xh">*</span>
        {{
          flag == 1
            ? "选择授权小区（楼栋、单元）"
            : "选择授权小区（楼栋、单元、楼层、房间号）"
        }}
      </div>
      <div class="from-item-bg" @click="show = true">{{ floorNames }}</div>
      <van-action-sheet v-model="show" title="请选择（支持多选）">
        <div
          class="van-checkbox-bg"
          v-for="(item, index) in objList"
          :key="item.id"
        >
          {{ flag == 1 ? sqname(item) : item.unitName + item.floorName }}
          <van-checkbox
            v-model="item.checked"
            shape="square"
            @change="change(index)"
          ></van-checkbox>
        </div>
      </van-action-sheet>

      <div class="v-form-item-title"><span class="xh">*</span> 授权名称</div>
      <input
        class="from-item-bg"
        type="text"
        placeholder="请填写"
        v-model="postObj.name"
      />

      <div v-if="flag == 2">
        <div class="v-form-item-title"><span class="xh">*</span> 生效时间</div>
        <div class="from-item-bg" @click="showStartTime = true">
          {{ postObj.startDate }}
        </div>

        <van-action-sheet v-model="showStartTime">
          <van-datetime-picker
            v-model="currentDate"
            type="datetime"
            title="生效时间"
            @confirm="confirmStart"
            @cancel="showStartTime = false"
          />
        </van-action-sheet>
      </div>

      <div v-if="flag == 2">
        <div class="v-form-item-title"><span class="xh">*</span> 失效时间</div>
        <div class="from-item-bg" @click="showEndTime = true">
          {{ postObj.endDate }}
        </div>

        <van-action-sheet v-model="showEndTime">
          <van-datetime-picker
            v-model="currentDate"
            type="datetime"
            title="失效时间"
            @confirm="confirmEnd"
            @cancel="showEndTime = false"
          />
        </van-action-sheet>
      </div>

      <div class="v-form-item-title"><span class="xh">*</span> 授权密码</div>
      <div class="passeword">
        {{
          flag == 1
            ? "注：系统随机自动生成4位数的密码"
            : "注：系统随机自动生成8位数的密码"
        }}
      </div>

      <div class="sub" @click="postAddPwdsData">确认生成</div>
    </div>
  </div>
</template>

<script>
import {
  getFloorList,
  postAddPwds,
  getSelect,
  postPwdDoor,
} from "@/api/authorize/authorizeApply";
export default {
  created() {
    this.flag = this.$route.query.flag;

    if (this.flag == 1) {
      this.getSelectData();
    } else {
      this.getFloorListData();
    }
  },
  data() {
    return {
      //1门禁授权 2门锁授权
      flag: -1,
      show: false,
      showStartTime: false,
      showEndTime: false,
      checked: false,
      objList: [],
      floorNames: "请选择（支持多选）",
      currentDate: new Date(),
      postObj: {
        name: "",
        startDate: "请选择",
        endDate: "请选择",
        floorIds: [],
      },
    };
  },
  methods: {
    confirmStart(val) {
      this.postObj.startDate = this.dateFormat(
        "YYYY-mm-dd HH:MM:SS",
        new Date(val)
      );
      this.showStartTime = false;
    },
    confirmEnd(val) {
      this.postObj.endDate = this.dateFormat(
        "YYYY-mm-dd HH:MM:SS",
        new Date(val)
      );
      this.showEndTime = false;
    },
    getFloorListData() {
      getFloorList({ current: 1, size: 999 }).then((res) => {
        this.objList = res.data.records;
      });
    },
    getSelectData() {
      getSelect().then((res) => {
        this.objList = res.data;
      });
    },
    sqname(obj) {
      return (
        (obj.communityName ? obj.communityName : "") +
        (obj.groupName ? "/" + obj.groupName : "") +
        (obj.buildingName ? "/" + obj.buildingName : "") +
        (obj.unitName ? "/" + obj.unitName : "")
      );
    },
    postAddPwdsData() {
      if (this.postObj.floorIds.length == 0) {
        this.$toast("请选择授权楼栋单元或房屋");
        return;
      }
      if (!this.postObj.name) {
        this.$toast("请填写授权名称");
        return;
      }
      if (this.flag == 2) {
        if (this.postObj.startDate === "请选择") {
          this.$toast("请选择生效时间");
          return;
        }
        if (this.postObj.endDate === "请选择") {
          this.$toast("请选择失效时间");
          return;
        }
      }

      if (this.flag == 1) {
        let obj = {
          authUserName: this.postObj.name,
          openCodeDTO: this.postObj.floorIds,
        };
        postPwdDoor(obj).then((res) => {
          if (res.code == 200) {
            this.$toast("授权成功");
            this.$router.go(-1); //返回上一层
          } else {
            this.$toast(res.msg);
          }
        });
      } else {
        postAddPwds(this.postObj).then((res) => {
          if (res.code == 200) {
            this.$toast("授权成功");
            this.$router.go(-1); //返回上一层
          } else {
            this.$toast(res.msg);
          }
        });
      }
    },
    change(i) {
      this.floorNames = "";
      this.postObj.floorIds = [];
      this.objList.forEach((element, index) => {
        if (element.checked) {
          if (this.flag == 1) {
            this.floorNames =
              this.floorNames +
              (element.communityName ? element.communityName : "") +
              (element.groupName ? "/" + element.groupName : "") +
              (element.buildingName ? "/" + element.buildingName : "") +
              (element.unitName ? "/" + element.unitName : "") +
              ";";
            this.postObj.floorIds = this.postObj.floorIds.concat(element);
            if (this.objList.length - 1 == index && !this.floorNames) {
              this.floorNames = "请选择（支持多选）";
            }
          } else {
            this.floorNames =
              this.floorNames + element.unitName + element.floorName + ";";
            this.postObj.floorIds = this.postObj.floorIds.concat(
              element.floorId
            );
            if (this.objList.length - 1 == index && !this.floorNames) {
              this.floorNames = "请选择（支持多选）";
            }
          }
        }
      });
    },

    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
  },
};
</script>

<style scoped lang="scss">
.van-checkbox-bg {
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: 400;
  color: #303030;
  line-height: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.box {
  padding-top: 2rem;

  .from-box {
    height: 100vh;
    background: #ffffff;
    border-radius: 1.2rem;
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    .from-item-bg {
      border-radius: 0.4rem;
      border: 0.1rem solid #e4e6eb;
      padding: 1rem 1.4rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .v-form-item-title {
      font-size: 1.4rem;
      font-weight: 500;
      color: #333333;
    }

    .xh {
      width: 0.5rem;
      height: 0.5rem;
      color: #f24949;
    }
    .passeword {
      padding: 1.1rem 1.4rem;
      margin-top: 0.5rem;
      background: #f6f7fb;
      border-radius: 0.4rem;
      font-size: 1.4rem;
      font-weight: 400;
      color: #f72323;
      line-height: 2rem;
    }
    .sub {
      background: #3186ff;
      box-shadow: -0.2rem -0.2rem 0.3rem 0rem #ffffff,
        0.3rem 0.2rem 0.5rem 0rem #a9c9e5;
      border-radius: 2.4rem;
      padding: 1.3rem;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 600;
      color: #ffffff;
      line-height: 2.2rem;
      margin-top: 15rem;
    }
  }
}
</style>